<template>
  <div class="slide-constructor">
    <!--COMPONENTS-->
    <draggable v-model="components" handle=".move-drag" @end="draggedComponent">
      <div v-for="(component,ind) in components" :key="'component-'+ind">
        <div class="position-relative component-wrapper"
             :class="{'d-none': !component.component.saved }"
             :id="'component-wrapper-'+component.component.id"
             :data-id="component.component.id"
             :data-index="ind">
          <div class="position-absolute component-buttons component-right-buttons">
            <div class="move-drag d-inline-block" v-if="canEdit"><MoveSvg/></div>
            <b-dropdown right
                        no-caret
                        menu-class="noscrollbar"
                        variant="link"
                        class="d-inline-block pointer">
              <template v-slot:button-content>
                <MoreSvg class="d-inline-block"/>
              </template>
              <b-dropdown-item @click="editContent(ind)"
                               v-if="canEdit">
                {{ $t('edit') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="!component.component.hidden_unit && canEdit"
                               @click="hideComponent(component.component.id)">
                {{ $t('hide') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="component.component.hidden_unit && canEdit"
                               @click="unhideComponent(component.component.id)">
                {{ $t('show') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="!isLightCourse"
                               @click="copyComponent(component.component)">
                {{ $t('copy') }}
              </b-dropdown-item>
              <b-dropdown-item @click="deleteBlock(component.component.id)" v-if="canEdit">{{ $t('delete') }}</b-dropdown-item>
            </b-dropdown>
            <!--                    <a class="btn btn-light mr-1" tabindex="#" @click="componentDuplicate(ind,component.component.id)">Дублировать</a>-->
          </div>
          <div v-if="component.component.hidden_unit">
            <div class="container py-4">
              <div @click="unhideComponent(component.component.id)"
                   class="btn-whole-add btn-whole-add-blue text-center">
                {{ $t('component_is_hidden') }}
              </div>
            </div>
          </div>
          <div v-else>
            <TaskComponentsParser :ind="ind"
                                  :slidecomponents="[component]"
                                  :host="true"
                                  source="constructor"
                                  :key="'parser-'+keyRand"
                                  :slide="index"/>
          </div>
          <div class="add-block-wrapper text-center" v-if="canEdit">
            <div class="add-new-block pointer"  @click="addBlockInit(ind)">
              <PlusSvg class="svg-icon-sm"/>
            </div>
          </div>
        </div>
      </div>
    </draggable>

    <!--CONTENT-->
    <b-modal id="constructor-content-modal" modal-class="modal-right-pane">
      <template v-slot:modal-header="{ close }">
        <h2 v-if="selected_component">{{ $t(selected_component.component.component_type.title) }}</h2>
        <a class="pointer position-relative modal-close" @click="close()">
          <CloseSvg/>
        </a>
      </template>
      <div v-if="selected_component">

        <div class="form-group">
          <label for="custom_number">{{ $t('block_number') }}</label>
          <input class="form-control form-control-white"
                 id="custom_number"
                 v-model="selected_component.component.custom_number"/>
        </div>

        <div class="form-group"
             v-for="(field,id) in selectedComponentStructure"
             :key="'content-'+id">
          <h3 v-if="field.type !== 'checkbox'"
              :for="'content-'+id">
            {{ $t(field.title) }}
            <HelpSvg class="help"
                     v-if="field.hint"
                     :id="'pop-'+id"/>
            <b-popover v-if="field.hint"
                       :target="'pop-'+id"
                       triggers="hover"
                       placement="top"
                       custom-class="gray-popover">
              {{ $t(field.hint) }}
            </b-popover>
          </h3>
          <template v-if="field.type === 'text'">
            <AutosizeTextArea class="form-control-white"
                              :id="'content-'+id"
                              :minH="50"
                              :maxH="200"
                              :white="true"
                              v-model="selected_component.component.content[id]">
            </AutosizeTextArea>
          </template>
          <template v-if="field.type === 'textarea'">
            <quill-editor class="form-control-white"
                          v-model="selected_component.component.content[id]"
                          :options="quillOptions"/>
          </template>
          <template v-if="field.type === 'checkbox'">
            <div class="checkbox form-group mt-4">
              <input type="checkbox"
                     value="1"
                     @click="checkboxClicked(field)"
                     v-model="selected_component.component.content[id]"
                     :id="'content-'+id"/>
              <label class="mb-0" :for="'content-'+id">{{ $t(field.title) }}</label>
            </div>
          </template>
          <template v-if="field.type === 'image'">
            <div class="position-relative d-inline-block"
                 v-if="selected_component.component.content[id]">
              <img alt="photo"
                   class="img-preview"
                   :src="selected_component.component.content[id]"/>
              <a class="removeFile"
                 @click="filesToDelete(id,0,0)">
                <CloseSvg/>
              </a>
            </div>
            <div class="position-relative" v-else>
              <div v-if="files_imgs[id+'-0-0']"
                   class="position-relative d-inline-block">
                <img alt="photo"
                     class="img-preview"
                     :src="files_imgs[id+'-0-0']"/>
                <a class="removeFile" @click="removeFile(id,0,0)">
                  <CloseSvg/>
                </a>
              </div>
              <div v-else>
                <label class="form-label mb-0" :for="'content-'+id">
                                    <span class="form-control form-control-white file-form-control">
                                        <span class="form-placeholder">{{ $t('file') }}</span>
                                        <span class="file-btn">{{ $t('select') }}</span>
                                    </span>
                </label>
                <input type="file" class="form-control d-none" accept="image/png,image/jpg,image/jpeg" :id="'content-'+id" @change="handleFileUploads(field.type, id, 0, 0, $event.target.files)"/>
              </div>
            </div>
          </template>

          <template v-if="field.type === 'select'">
            <SelectGroup class="default"
                         :hasNullOption="false"
                         v-model="selected_component.component.content[id]"
                         :options="field.options"/>
          </template>

          <template v-if="field.type === 'audio'">
            <div class="position-relative"
                 v-if="selected_component.component.content[id]">
              <audio controls
                     class="w-100"
                     :key="'audio-'+keyRand">
                <source :src="selected_component.component.content[id]"
                        type="audio/mpeg">
              </audio>
              <a class="removeFile" @click="filesToDelete(id,0,0)"><CloseSvg/></a>
            </div>
            <div class="position-relative" v-else>
              <div v-if="files_imgs[id+'-0-0']" class="position-relative">
                <audio controls>
                  <source :src="files_imgs[id+'-0-0']" type="audio/mpeg"  :key="'audios-'+keyRand">
                </audio>
                <a class="removeFile" @click="removeFile(id,0,0)">
                  <CloseSvg/>
                </a>
              </div>
              <div v-else>
                <label class="form-label mb-0" :for="'content-'+id">
                  <span class="form-control form-control-white file-form-control">
                    <span class="form-placeholder">{{ $t('file') }}</span>
                    <span class="file-btn">{{ $t('select') }}</span>
                  </span>
                </label>
                <input type="file"
                       accept=".mp3,.wav"
                       class="form-control d-none"
                       :id="'content-'+id"
                       @change="handleFileUploads(field.type, id, 0, 0, $event.target.files)"/>
              </div>
            </div>
          </template>


          <template v-if="field.type === 'audio_config'">
            <AudioConfigPart v-model="selected_component.component.content[id]"/>
          </template>

          <template v-if="field.type === 'table'">
            <TableCreationPart :table="selected_component.component.content[id]"/>
          </template>

          <template v-if="field.type === 'multiple'">
            <div class="py-3 mb-4 position-relative border-bottom"
                 v-for="(cardval, cardindex) in selected_component.component.content[id]"
                 :key="'multiple-'+field.title+'-'+cardindex">
              <div class="row">
                <div class="col pointer card-collapse" v-b-toggle="'collapse-'+id+'-'+cardindex">
                  {{ $t(field.cardtitle) }} {{ (cardindex + 1) }}<span class="collapse-caret"><DownSvg/></span>
                </div>
                <div class="col-auto">
                  <div class="deleteMultipleButton">
                    <a class="up-svg align-middle" @click="moveUp(id,cardindex)"><DownSvg/></a>
                    <a class="down-svg align-middle ml-1" @click="moveDown(id,cardindex)"><DownSvg/></a>
                    <a class="d-inline-block align-middle ml-1" @click="deleteMultipleField(id,cardindex)"><CloseSvg/></a>
                  </div>
                </div>
              </div>
              <b-collapse :id="'collapse-'+id+'-'+cardindex"
                          :visible="cardindex == 0 && selected_component.component.content[id].length == 1">
                <div class="pt-3">
                  <div class="form-group"
                       v-for="(childfield, childfieldid) in field.structure"
                       :class="{
                         'd-inline-block w-50 pr-3 align-top': ['word1','word2'].includes(childfieldid)
                       }"
                       :key="'multiple-'+field.title+'-'+cardindex+'-'+childfield.title">
                    <label :for="'content-'+id+'-'+cardindex+'-'+childfieldid">{{ $t(childfield.title) }}</label>
                    <template v-if="childfield.type === 'text'">
                      <AutosizeTextArea class="form-control-white"
                                        :id="'content-'+id+'-'+cardindex+'-'+childfieldid"
                                        :minH="50"
                                        :maxH="200"
                                        :white="true"
                                        v-model="selected_component.component.content[id][cardindex][childfieldid]">
                      </AutosizeTextArea>
                    </template>
                    <template v-if="childfield.type === 'textarea'">
                                            <textarea rows="5"
                                                      :id="'content-'+id+'-'+cardindex+'-'+childfieldid"
                                                      class="form-control"
                                                      v-model="selected_component.component.content[id][cardindex][childfieldid]"></textarea>
                    </template>
                    <template v-if="childfield.type === 'select'">
                      <SelectGroup class="default"
                                   :key="'select-'+field.title+'-'+cardindex+'-'+childfield.title+'__'+keyRand"
                                   :hasNullOption="false"
                                   v-model="selected_component.component.content[id][cardindex][childfieldid]"
                                   :options="childfield.options"/>
                    </template>
                    <template v-if="childfield.type === 'multiple'">
                      <div class="multiple-wrapper mb-0 position-relative"
                           v-for="(cccardval, cccardindex) in selected_component.component.content[id][cardindex][childfieldid]"
                           :key="'multiple-'+field.title+'-'+cardindex+'-'+childfield.title+'-'+cccardindex">
                        <div class="deleteMultipleButton position-absolute">
                          <a class=""
                             @click="deleteMultipleField(id,cardindex,childfieldid, cccardindex)"><CloseSvg/></a>
                        </div>

                        <div class="form-group mb-0"
                             v-for="(ccchildfield, ccchildfieldid) in childfield.structure"
                             :key="'multiple-'+field.title+'-'+cardindex+'-'+childfield.title+'-'+cccardindex+'-'+ccchildfield.title">
                          <template v-if="ccchildfield.type === 'text'">
                            <AutosizeTextArea class="form-control-white mr-5 ml-5"
                                              :id="'content-'+id+'-'+cardindex+'-'+childfieldid+'-'+cccardindex+'-'+ccchildfieldid"
                                              :minH="50"
                                              :maxH="200"
                                              :placeholder="$t(ccchildfield.title) + ' ' + (cccardindex+1)"
                                              :white="true"
                                              v-model="selected_component.component.content[id][cardindex][childfieldid][cccardindex][ccchildfieldid]">
                            </AutosizeTextArea>
                          </template>
                          <template v-if="ccchildfield.type === 'checkbox'">
                            <div class="checkbox form-group" :class="{ 'checkbox-correct': ccchildfield.title == 'correct' }">
                              <input type="checkbox"
                                     value="1"
                                     v-model="selected_component.component.content[id][cardindex][childfieldid][cccardindex][ccchildfieldid]"
                                     :id="'content-'+id+'-'+cardindex+'-'+childfieldid+'-'+cccardindex+'-'+ccchildfieldid"/>
                              <label class="mb-0" :for="'content-'+id+'-'+cardindex+'-'+childfieldid+'-'+cccardindex+'-'+ccchildfieldid"></label>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="mt-3">
                        <a class="btn-whole-add py-0 h-auto" style="line-height: normal" @click="addMultipleField(id, childfield.structure, cardindex, childfieldid)">
                          <PlusSvg class="mr-3 svg-icon-sm"/>{{ childfield.addbtntitle ? $t(childfield.addbtntitle) : $t('add') }}
                        </a>
                      </div>
                    </template>
                    <template v-if="childfield.type === 'image'">
                      <div class="position-relative"
                           v-if="selected_component.component.content[id][cardindex][childfieldid]">
                        <img alt="photo"
                             class="img-preview"
                             :src="selected_component.component.content[id][cardindex][childfieldid]"/>
                        <a class="removeFile"
                           @click="filesToDelete(id,cardindex,childfieldid)"><svg class="svg-close" fill="#fff"><use xlink:href="#close_svg"></use></svg></a>
                      </div>
                      <div class="position-relative" v-else>
                        <div v-if="files_imgs[id+'-'+cardindex+'-'+childfieldid]">
                          <img alt="photo" class="img-preview" :src="files_imgs[id+'-'+cardindex+'-'+childfieldid]"/>
                          <a class="removeFile" @click="removeFile(id,cardindex,childfieldid)"><svg class="svg-close" fill="#fff"><use xlink:href="#close_svg"></use></svg></a>
                        </div>
                        <div v-else>
                          <input type="file"
                                 :id="'content-'+id+'-'+cardindex+'-'+childfieldid"
                                 @change="handleFileUploads(childfield.type, id, cardindex, childfieldid, $event.target.files)"/>
                        </div>
                      </div>
                    </template>

                    <div v-if="['word1'].includes(childfieldid)">
                      <div class="checkbox form-group mt-4">
                        <input type="checkbox"
                               value="1"
                               v-model="selected_component.component.content[id][cardindex][childfieldid+'_audio']"
                               :id="'content-'+id+'-'+cardindex+'-'+childfieldid+'_audio'"/>
                        <label class="mb-0" :for="'content-'+id+'-'+cardindex+'-'+childfieldid+'_audio'">{{ $t('audio') }}</label>
                      </div>

                      <template v-if="selected_component.component.content[id][cardindex][childfieldid+'_audio']">
                        <div class="position-relative" v-if="selected_component.component.content[id][cardindex][childfieldid+'_audio_file']">
                          <audio controls
                                 class="w-100"
                                 :key="'audio-'+keyRand">
                            <source :src="selected_component.component.content[id][cardindex][childfieldid+'_audio_file']" type="audio/mpeg">
                          </audio>
                          <a class="removeFile" @click="filesToDelete(id,cardindex,childfieldid+'_audio_file')"><CloseSvg/></a>
                        </div>
                        <div class="position-relative" v-else>
                          <div v-if="files_imgs[id+'-'+cardindex+'-'+childfieldid+'_audio_file']" class="position-relative">
                            <audio controls>
                              <source :src="files_imgs[id+'-'+cardindex+'-'+childfieldid+'_audio_file']" type="audio/mpeg"  :key="'audios-'+keyRand">
                            </audio>
                            <a class="removeFile" @click="removeFile(id,cardindex,childfieldid+'_audio_file')">
                              <CloseSvg/>
                            </a>
                          </div>
                          <div v-else>
                            <label class="form-label mb-0" :for="'content-'+id+'-'+cardindex+'-'+childfieldid+'_audio_file'">
                                    <span class="form-control form-control-white file-form-control">
                                      <span class="form-placeholder">{{ $t('file') }}</span>
                                      <span class="file-btn">{{ $t('select') }}</span>
                                    </span>
                            </label>
                            <input type="file"
                                   accept=".mp3,.wav"
                                   class="form-control d-none"
                                   :id="'content-'+id+'-'+cardindex+'-'+childfieldid+'_audio_file'"
                                   @change="handleFileUploads('audio', id, cardindex, childfieldid+'_audio_file', $event.target.files)"/>
                          </div>
                        </div>
                      </template>
                    </div>

                    <div v-if="childfield.hint"><small>{{ $t(childfield.hint) }}</small></div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <div>
              <a class="btn-whole-add btn-whole-add-dashed"
                 @click="addMultipleField(id, field.structure)">
                <span class="svg-wrapper mr-3">
                  <PlusSvg class="svg-icon-sm"/>
                </span>{{ field.addbtntitle ? $t(field.addbtntitle) : $t('add') }}
              </a>
            </div>
          </template>
        </div>


        <div v-if="isLightCourse" class="pb-4">
          <h3 class="mb-3">{{ $t('lightcourse') }}</h3>
          <div class="spacecard gray p-4">
            <b-row>
              <b-col md="6">
                <div class="form-group">
                  <label for="scores">{{ $t('scores') }}</label>
                  <InputGroup v-model="selected_component.component.light_scores"
                              id="scores"
                              type="number"
                              class="default"/>
                </div>
              </b-col>
              <b-col md="6">
                <div class="form-group">
                  <label for="words">{{ $t('words') }}</label>
                  <InputGroup v-model="selected_component.component.light_words"
                              id="words"
                              type="number"
                              class="default"/>
                </div>
              </b-col>
              <b-col md="12">
                <CheckboxGroup v-model="selected_component.component.light_lives"
                               fid="lives"
                               :label="$t('write_off_lives')"/>
              </b-col>
            </b-row>
          </div>
        </div>

      </div>
      <template v-slot:modal-footer>
        <b-row class="no-gutters">
          <b-col cols="12">
            <button class="btn btn-themed w-100"
                    data-dismiss="modal"
                    @click="saveContent">
              {{ $t('save') }}
            </button>
          </b-col>
        </b-row>
      </template>
    </b-modal>


    <b-modal id="block-type-select" modal-class="modal-right-pane" hide-footer centered>
      <template v-slot:modal-header="{ close }">
        <h2>{{ $t('choose_element') }}</h2>
        <a class="pointer position-relative modal-close" @click="close()">
          <CloseSvg/>
        </a>
      </template>
      <div class="mt-4">
        <b-row>
          <b-col cols="6" v-for="type in componentTypes" :key="'comp-value-sep-'+type.code" class="mb-3">
            <ComponentTypeTeaser :component="type"
                                 @click.native="newBlockTypeSelected(type)"
                                 :data-id="type.code"
                                 class="h-100"/>
            <!--                            <button class="btn-themed btn-white d-block w-100" @click="newBlockTypeSelected(ct)">{{ ct.title }}</button>-->
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <CopyComponentModal @componentsUpdate="componentsUpdate"/>

    <!--SEPARATE ADD BLOCK (IN CASE NO COMPONENTS EXIST)-->
    <div :class="{ 'mt-4': components.length > 0}" v-if="canEdit">
      <a class="btn-whole-add btn-whole-add-dashed" @click="addBlockInit(components.length)">
        <span class="svg-wrapper mr-3"><PlusSvg class="svg-icon-sm"/></span>{{ $t('add_block') }}
      </a>
    </div>

  </div>
</template>

<script>

import { ConstructorService } from '@/services/api.service'
import DownSvg from "@/assets/svg/down.svg"
import MoreSvg from "@/assets/svg/more.svg"
import MoveSvg from "@/assets/svg/move2.svg"
import PlusSvg from "@/assets/svg/plus.svg"
import HelpSvg from "@/assets/svg/help.svg"
import CloseSvg from "@/assets/svg/close.svg"
import ComponentTypeTeaser from "@/views/teasers/ComponentTypeTeaser"
import AutosizeTextArea from "@/views/parts/general/form/AutosizeTextArea"
import TaskComponentsParser from "@/views/parts/class/preview/TaskComponentsParser"
import "@/assets/sass/constructor.scss"
import draggable from 'vuedraggable'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import SelectGroup from "../../../parts/general/form/SelectGroup"
import TableCreationPart from "../../../parts/constructor/parts/TableCreationPart"
import AudioConfigPart from "../../../parts/constructor/parts/AudioConfigPart"
import CopyComponentModal from "../../../parts/constructor/parts/CopyComponentModal"
import InputGroup from "../../../parts/general/form/InputGroup.vue"
import CheckboxGroup from "../../../parts/general/form/CheckboxGroup.vue"

export default {
  components: {
    CheckboxGroup,
    InputGroup,
    CopyComponentModal,
    TableCreationPart,
    AudioConfigPart,
    SelectGroup,
    MoreSvg,
    CloseSvg,
    MoveSvg,
    PlusSvg,
    HelpSvg,
    DownSvg,
    ComponentTypeTeaser,
    AutosizeTextArea,
    quillEditor,
    TaskComponentsParser,
    draggable
  },
  mounted() {
    this.initialLoad()
    document.addEventListener('click',(e) => {
      if(e.target && (e.target.classList.contains('st__input') || (e.target.parentElement && e.target.parentElement.classList.contains('st__input')))) {
        this.editContent(e.target.closest('.component-wrapper').dataset.index)
      }
    })
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === "constructor-content-modal") {
        setTimeout(() => {
          this.randomizeKey()
          this.testForUnknownOptionInTrueFalse()
        }, 20)
      }
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === "constructor-content-modal") {
        this.components = this.components.filter(function( obj ) {
          return obj.component.saved
        })
        setTimeout(() => { this.randomizeKey() }, 20)
      }
    })

  },
  methods: {
    initialLoad() {
      ConstructorService.getSlideForConstructor(this.slide.id).then(res => {
        this.componentTypes = res.data.data.types
        this.components = res.data.data.components
      })
    },
    saveContent() {
      if(this.selected_component) {
        let formData = new FormData()
        formData.append('component', JSON.stringify(this.selected_component))
        formData.append('filestodelete', JSON.stringify(this.files_to_delete))
        var i
        for(i in this.files) {
          if({}.hasOwnProperty.call(this.files, i)) {
            let file = this.files[i]
            formData.append('files[' + i + ']', file)
          }
        }
        ConstructorService.updateBlock(this.slide.id, this.selected_component.component.id, formData).then(res => {
          this.$set(this.components, this.selected_index, res.data.data)
          this.selected_component = null
          this.selected_index = null
          this.files = {}
          this.files_imgs = {}
          this.files_to_delete = []
          this.$bvModal.hide('constructor-content-modal')
        }).catch(err => {
          this.errResponse(err)
        })
      }
    },
    draggedComponent() {
      let ids = this.components.map(a => a.component.id)
      ConstructorService.resortBlocks(this.slide.id, ids).then(() => {
        this.randomizeKey()
      })
    },
    hideComponent(id) {
      ConstructorService.hideBlock(this.slide.id, id).then(res => {
        this.components = res.data.data
      })
    },
    unhideComponent(id) {
      ConstructorService.unhideBlock(this.slide.id, id).then(res => {
        this.components = res.data.data
      })
    },
    editContent(ind) {
      this.selected_index = ind
      this.selected_component = JSON.parse(JSON.stringify(this.components[ind]))
      if(this.selected_component.component.content.length == 0) {
        this.selected_component.component.content = {}
      }
      this.files = {}
      this.files_imgs = {}
      this.files_to_delete = []
      this.$bvModal.show('constructor-content-modal')
    },
    copyComponent(component) {
      this.$store.commit('setComponentToCopy', component)
      this.$bvModal.show('slide-copy-select')
    },
    componentsUpdate(data) {
      this.components = data
    },
    addBlockInit(ind) {
      ConstructorService.removeUnsavedBlocks(this.slide.id)
      this.selected_index = ind
      this.$bvModal.show('block-type-select')
    },
    newBlockTypeSelected(nb) {
      this.newblock = nb
      this.addBlock(this.selected_index)
    },
    addBlock(index) {
      ConstructorService.addBlock(this.slide.id, this.newblock.id, index).then(res => {
        this.components = res.data.data
        this.newblock = ""
        this.$bvModal.hide('block-type-select')
        this.$nextTick(() => {
          this.editContent(index)
        })

      })
    },
    deleteBlock(component_id) {
      ConstructorService.removeBlock(this.slide.id, component_id).then(res => {
        this.components = res.data.data
        this.randomizeKey()
      })
    },
    addMultipleField(fieldid, structure, cardindex = null, childfieldid = null) {
      let obj = {}
      for(var x in structure) {
        let s = structure[x]
        if(s.type == 'multiple') {
          obj[x] = []
        } else {
          if(x != 'img') {
            obj[x] = ""
          }
        }
      }
      if(childfieldid) {
        this.selected_component.component.content[fieldid][cardindex][childfieldid].push(obj)
      } else {
        this.selected_component.component.content[fieldid].push(obj)
        setTimeout(() => {
          let index = this.selected_component.component.content[fieldid].length - 1
          if(index > 0) {
            let id = 'collapse-'+fieldid+'-'+index
            this.$root.$emit('bv::toggle::collapse', id)
          }
        },10)
      }
    },
    deleteMultipleField(fieldid, cardindex, childid = null, childcardindex = null) {
      if(childid) {
        this.selected_component.component.content[fieldid][cardindex][childid].splice(childcardindex,1)
      } else {
        let content = this.selected_component.component.content[fieldid][cardindex]
        if(content.img) {
          this.files_to_delete.push(content.img)
        }
        this.selected_component.component.content[fieldid].splice(cardindex,1)
      }

    },
    moveUp(fieldid, cardindex) {
      if(cardindex === 0) {
        return
      }
      let content = this.selected_component.component.content[fieldid]
      const b = content[cardindex]
      this.$set(content,cardindex,content[(cardindex-1)])
      this.$set(content,cardindex-1,b)
      const file = this.files[fieldid+'-'+cardindex+'-img']
      this.$set(this.files,(fieldid+'-'+cardindex+'-img'),this.files[fieldid+'-'+(cardindex-1)+'-img'])
      this.$set(this.files,(fieldid+'-'+(cardindex-1)+'-img'),file)
      const file_img = this.files_imgs[fieldid+'-'+cardindex+'-img']
      this.$set(this.files_imgs,(fieldid+'-'+cardindex+'-img'),this.files_imgs[fieldid+'-'+(cardindex-1)+'-img'])
      this.$set(this.files_imgs,(fieldid+'-'+(cardindex-1)+'-img'),file_img)
    },
    moveDown(fieldid, cardindex) {
      if(cardindex >= (this.selected_component.component.content[fieldid].length - 1)) {
        return
      }
      let content = this.selected_component.component.content[fieldid]
      var b = content[cardindex]
      this.$set(content,cardindex,content[(cardindex+1)])
      this.$set(content,cardindex+1,b)
      const file = this.files[fieldid+'-'+cardindex+'-img']
      this.$set(this.files,(fieldid+'-'+cardindex+'-img'),this.files[fieldid+'-'+(cardindex+1)+'-img'])
      this.$set(this.files,(fieldid+'-'+(cardindex+1)+'-img'),file)
      const file_img = this.files_imgs[fieldid+'-'+cardindex+'-img']
      this.$set(this.files_imgs,(fieldid+'-'+cardindex+'-img'),this.files_imgs[fieldid+'-'+(cardindex+1)+'-img'])
      this.$set(this.files_imgs,(fieldid+'-'+(cardindex+1)+'-img'),file_img)
    },
    handleFileUploads(type, id, cardindex, childfieldid, uploadedFiles) {
      const fileid = id+'-'+cardindex+'-'+childfieldid
      let i = 0
      for( i = 0; i < uploadedFiles.length; i++ ) {
        let sizelimit = 1
        let format = /\.(jpe?g|png|gif|svg)$/i
        if(type == 'audio') {
          sizelimit = 7;
          format = /\.(mp3|wav)$/i
        }
        if (! format.test( uploadedFiles[i].name ) ) {
          this.$error("У файла неверный формат")
          return
        }
        if(uploadedFiles[i].size > (sizelimit * 1024 * 1024)) {
          this.$error("Файл должен быть меньше "+sizelimit+"МБ")
          return
        }
      }
      for( i = 0; i < uploadedFiles.length; i++ ) {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.$set(this.files_imgs, fileid, e.target.result)
        }
        reader.readAsDataURL(uploadedFiles[i])
        this.$set(this.files, fileid, uploadedFiles[i])
      }
    },
    removeFile(id,cardindex,childfieldid) {
      const fileid = id+'-'+cardindex+'-'+childfieldid
      this.$set(this.files_imgs,fileid,null)
      this.$set(this.files,fileid,null)
    },
    filesToDelete(id, cardindex, childfieldid) {
      if(cardindex == 0 && childfieldid == 0) {
        this.files_to_delete.push(this.selected_component.component.content[id])
        this.selected_component.component.content[id] = null
      }  else {
        this.files_to_delete.push(this.selected_component.component.content[id][cardindex][childfieldid])
        this.selected_component.component.content[id][cardindex][childfieldid] = null
      }

    },
    getNumbers:function(start,stop,step){
      if(!step) { step = 1 }
      return new Array(stop-start).fill(start).map((n,i)=>n+i*step)
    },
    randomizeKey() {
      this.keyRand = Math.random()
    },
    checkboxClicked(field) {
      if(field.title === "allow_unknown") {
        setTimeout(() => this.testForUnknownOptionInTrueFalse(), 20)
      }
    },
    testForUnknownOptionInTrueFalse() {
      if(!this.selected_component || !this.selected_component.component) return
      if(this.selected_component.component.component_type.code === 'TS09') {
        let showUnknown = this.selected_component.component.content.allow_unknown
        let options = this.selectedComponentStructure.questions.structure.right.options
        if(showUnknown && options.length < 3) {
          options.push({ id: 'unknown', title: 'unknown' })
        }
        if(!showUnknown && options.length >= 3) {
          options.splice(-1)
        }
        this.$set(this.selectedComponentStructure.questions.structure.right, 'options', options)
        this.randomizeKey()
      }
    },
  },
  watch:{
    slide() {
      this.initialLoad()
    }
  },
  computed: {
    selectedComponentStructure() {
      if(this.selected_component) {
        return JSON.parse(this.selected_component.component.component_type.structure)
      }
      return null
    },
    onlyCopy() {
      return false
      // let user = this.$store.state.user
      // if(user.primary_role_id != 6) {
      //   return false
      // }
      // return this.course.instructor_id != user.id
    },
    canEdit() {
      return !this.onlyCopy
    }
  },
  props: {
    slide: {},
    index: {},
    course: {},
    isLightCourse: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      fade: false,
      componentTypes: [],
      components: [],
      selected_component: null,
      selected_index: null,
      newblock: "",
      files: {},
      files_imgs: {},
      files_to_delete: [],
      keyRand: 1,
      quillOptions: {
        tagName: "div",
        placeholder: '...',
        modules: {
          toolbar: [
            [
              'bold',
              'italic',
              'underline',
              'strike',
              // 'table',
              // { 'header': 1 },
              // { 'header': 2 },
              { 'list': 'ordered'},
              { 'list': 'bullet' },
              { 'indent': '-1'},
              { 'indent': '+1' },
              { 'color': [] },
              { 'background': [] },
              { 'align': [] },
              'link',
              'clean',
              // ['table', 'column-left', 'column-right', 'row-above', 'row-below', 'row-remove', 'column-remove'],
              // [{ 'table': 'append-row' }], // cursor needs to be in the table
              // [{ 'table': 'append-col' }], // cursor needs to be in the table
            ],
          ],
          // table: true,
          clipboard: {
            matchers: [
              //         [ Node.ELEMENT_NODE, function(node, delta) {
              //             var plaintext = node.innerText
              //             var Delta = Quill.import('delta')
              //             return new Delta().insert(plaintext)
              //             return delta.compose(new Delta().retain(delta.length()))
              //         }]
              [ Node.TEXT_NODE, function(node, delta) {
                var regex = /https?:\/\/[^\s]+/g;
                if(typeof(node.data) !== 'string') return;
                var matches = node.data.match(regex);

                if(matches && matches.length > 0) {
                  var ops = [];
                  var str = node.data;
                  matches.forEach(function(match) {
                    var split = str.split(match);
                    var beforeLink = split.shift();
                    ops.push({ insert: beforeLink });
                    ops.push({ insert: match, attributes: { link: match } });
                    str = split.join(match);
                  });
                  ops.push({ insert: str });
                  delta.ops = ops;
                }
                return delta;
              }]
            ]
          }
        }
      }
    }
  }
}
</script>
