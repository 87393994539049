<template>
    <div class="component-type-teaser" :class="{ active: component.ready }">
        <img :src="component.img_url"/>
        <h5>{{ $t(component.title) }}</h5>
    </div>
</template>

<script>

    export default {
        props: {
            component: {

            }
        }
    }
</script>

<style scoped lang="scss">
    .component-type-teaser {
        padding: 12px;
        background: $almost-white;
        border-radius: 16px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        opacity: 0.4;
        img {
            border-radius: 14px;
        }
        h5 {
            font-weight: 600;
            margin-top: 14px;
            font-size: 14px;
            padding: 0 12px;
        }
        &:hover {
            box-shadow: 0px 4px 12px 1px rgba(82, 83, 87, 0.25);
        }
        &.active {
            opacity: 1;
            /*h5 {*/
            /*    color: $text-success; */
            /*}*/
        }

    }
</style>






