<template>
  <div>
    <h3 class="pt-1">{{ $t('table_size') }}</h3>
    <div class="setting-wrapper">
      <b-row class="align-items-center">
        <b-col sm="6" cols="12">
          <PlusMinus v-model="table.width"
                     class="mb-3"
                     :min="0"
                     :max="500"
                     :step="10"
                     :label="`${$t('cells_width')} (px)`"/>
        </b-col>
        <b-col sm="6" cols="12">
          <PlusMinus v-model="table.height"
                     class="mb-3"
                     :min="0"
                     :max="500"
                     :step="10"
                     :label="`${$t('cells_height')} (px)`"/>
        </b-col>
        <b-col sm="6" cols="12">
          <PlusMinus v-model="table.rows"
                     class="mb-3 mb-sm-0"
                     :min="1"
                     :max="20"
                     :step="1"
                     :label="$t('rows_count')"/>
        </b-col>
        <b-col sm="6" cols="12">
          <PlusMinus v-model="table.cols"
                     class="mb-3 mb-sm-0"
                     :min="1"
                     :max="8"
                     :step="1"
                     :label="$t('columns_count')"/>
        </b-col>
      </b-row>
    </div>
    <h3>{{ $t('task_conditions') }} 
      <HelpSvg class="help" id="pop-3"/>
      <b-popover
          target="pop-3"
          triggers="hover"
          placement="top"
          custom-class="gray-popover">
        {{ $t('table_conditions_hint') }}
      </b-popover>
    </h3>
    
    <div class="space_general_component">
      <div class="task-table-wrapper" :class="{ 'd-inline-block': table.width > 0 }">
        <table class="task-table" :class="{ 'w-auto': table.width > 0 }">
          <tbody>
          <tr v-for="rowIndex in table.rows" :key="`row-${rowIndex}`">
            <td v-for="colIndex in table.cols"
                :style="{ width: table.width ? table.width+'px' : 'auto', height: table.height ? table.height+'px' : 'auto' }"
                :key="`col-${colIndex}`">
              <AutosizeTextArea class="task-table-input"
                                :class="{ 'font-weight-bold_': isBold(table.content[(rowIndex - 1)][(colIndex - 1)]) }"
                                v-if="table.content[(rowIndex - 1)] !== undefined"
                                :minH="34"
                                :maxH="200"
                                :white="true"
                                v-model="table.content[(rowIndex - 1)][(colIndex - 1)]">
              </AutosizeTextArea>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import PlusMinus from "../../general/form/PlusMinus"
import AutosizeTextArea from "@/views/parts/general/form/AutosizeTextArea"
import HelpSvg from "@/assets/svg/help.svg"

export default {
  name: "TableCreationPart",
  props: {
    table: {}
  },
  components: {
    PlusMinus, 
    AutosizeTextArea,
    HelpSvg
  },
  computed: {
    rows() {
      return this.table.rows
    },
    cols() {
      return this.table.cols
    },
  },
  methods: {
    updateContentOnNewStructure() {
      let newContent = []
      let currentContent = this.table.content
      for (var rowIndex = 0; rowIndex < this.table.rows; rowIndex++) {
        newContent[rowIndex] = []
        for (var colIndex = 0; colIndex < this.table.cols; colIndex++) {
          if(currentContent[rowIndex] !== undefined) {
            newContent[rowIndex][colIndex] = currentContent[rowIndex][colIndex] ? currentContent[rowIndex][colIndex] : ''  
          } else {
            newContent[rowIndex][colIndex] = ""
          }
        }
      }
      this.table.content = newContent
    },
    isBold(string) {
      if(string.length < 2) return false
      return string[0] === '*' && string[string.length - 1] === '*'
    }
  },
  watch: {
    rows() {
      this.updateContentOnNewStructure()
    },
    cols() {
      this.updateContentOnNewStructure()
    },
  }
}
</script>

<style scoped lang="scss">
.setting-wrapper {
  background: $gray-back2;
  border-radius: 12px;
  padding: 18px 24px;
}
</style>
