<template>
    <b-row class="align-items-center">
      <b-col cols="6">
        <div class="table-setting-label">{{ label }}</div>
      </b-col>
      <b-col cols="6">
        <div class="plus-minus-wrapper">
        <a @click="down" class="pointer"><MinusSvg class="svg-darkgray"/></a>
        <span class="p-m-value unselectable">{{ content }}</span>
        <a @click="up" class="pointer"><PlusSvg class="svg-darkgray svg-md"/></a>
        </div>
      </b-col>
    </b-row>
</template>

<script>

import PlusSvg from '@/assets/svg/plus2.svg'
import MinusSvg from '@/assets/svg/minus.svg'

export default {
  name: "PlusMinus",
  components: {
    PlusSvg,
    MinusSvg,
  },
  props: {
    step: { default: 1 },
    value: {},
    label: {},
    min: { default: 0 },
    max: { default: 100 }
  },
  data() {
    return {
      content: this.value
    }
  },
  methods: {
    up() {
      if(parseInt(this.content) >= this.max) return
      this.content = parseInt(this.content) + this.step
      this.handleInput()
    },
    down() {
      if(parseInt(this.content) <= this.min) return
      this.content = parseInt(this.content) - this.step
      this.handleInput()
    },
    handleInput() {
      this.$emit('input', this.content)
    }
  }
}
</script>

<style scoped lang="scss">
.plus-minus-wrapper {
  width: 120px;
  height: 40px;
  line-height: 38px;
  background: #FFFFFF;
  border: 1px solid #DADFEB;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  .p-m-value {
    font-weight: 600;
    font-size: 18px;
    
  }
  svg {
    width: 18px;
    height: 18px;
  }
}
.table-setting-label {
  font-weight: bold;
  font-size: 12px;
  color: $text-gray2;
}
</style>
