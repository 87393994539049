<template>
  <span class="horizontal-loader"></span>
</template>

<script>
export default {
  name: "HorizontalLoader"
}
</script>

<style scoped lang="scss">
.horizontal-loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: #e5e5e5;
  overflow: hidden;
}
.horizontal-loader::after {
  content: '';
  width: 192px;
  height: 4.8px;
  background: $text-themed;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}
</style>
