<template>
  <div class="form-group">
    <div class="select-wrapper" :class="{ 'with-floating-label': floatinglabel }">
      <select class="form-control"
              :disabled="readonly"
              @input="handleInput" 
              :value="value">
        <option :value="null" v-if="hasNullOption">-</option>
        <option v-for="o in options" :value="valuekey ? o[valuekey] : o.id" :key="'opt-'+o.id+'-'+label">
          {{ title ? t(o,title) : (translate ? $t(o.title) : o.title) }}
        </option>
      </select>

      <label class="select-form-label floating-label" :data-content="label" v-if="floatinglabel">
        <span class="hidden--visually">{{ label }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectGroup',
  data() {
    return {
      content: this.value
    }
  },
  props: {
    label: { type: String },
    value: {},
    valuekey: {},
    options: {},
    readonly: { type: Boolean, default: false },
    floatinglabel: { type: Boolean, default: false },
    translate: { type: Boolean, default: true },
    hasNullOption: { type: Boolean, default: true },
    title: { type: String },
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
