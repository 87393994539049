<template>
  <div>
    <div v-if="slide">
      <div class="spacecard md">
        <div v-if="!editing">
          <h2 class="slide-header border-bottom-dashed">{{ title }}
            <PenSvg @click="startEditing" v-if="canEdit" class="pointer mb-1 svg-icon-sm ml-2"/>
          </h2>
        </div>
        <div class="position-relative  border-bottom-dashed pb-3" v-if="editing">
          <input class="form-control padded"
                 ref="slidename"
                 @keyup="changed = true"
                 @blur="blur"
                 v-model="title"/>
          <div class="btn-inter">
            <b-button v-if="candelete"
                      @click="destroy"
                      class="btn-themed btn-white">
              <TrashSvg/>
            </b-button>
            <b-button v-if="changed"
                      class="btn-themed ml-2"
                      @click.stop="save">
              {{ $t('save') }}
            </b-button>
          </div>
        </div>
        <SlideConstructor :slide="slide"
                          :course="course"
                          :index="index"
                          :isLightCourse="isLightCourse"
                          :key="'sl-'+slide.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import { SlidesService } from '@/services/api.service'
import TrashSvg from '@/assets/svg/trash2.svg'
import PenSvg from '@/assets/svg/pen2.svg'
import SlideConstructor from '@/views/pages/instructor/courses/SlideConstructor'

export default {
  components: {
    TrashSvg,
    SlideConstructor,
    PenSvg
  },
  data() {
    return {
      slide: null,
      changed: false,
      candelete: false,
      title: "",
      editing: false,
      saving: false
    }
  },
  props: {
    index: {},
    passedslide: {},
    material: {},
    course: {},
    canEdit: {},
  },
  computed: {
    isLightCourse() {
      return !!this.material.light_course_lesson_id
    }
  },
  mounted() {
    this.slide = this.passedslide
    this.title = this.slide.title
  },
  watch: {
    passedslide(val) {
      this.slide = val
      this.title = this.slide.title
    },
    title() {
      this.saving = false
    }
  },
  methods:  {
    blur() {
      setTimeout(() => {
        if(!this.saving) {
          this.changed = false
          this.editing = false
          this.title = this.slide.title
        }
      },200)
    },
    startEditing() {
      this.editing = true
      this.$nextTick(() => {
        this.$refs.slidename.focus()
      })
    },
    save() {
      this.saving = true
      SlidesService.update(
          this.isLightCourse ? 'light' : this.course.id,
          this.material.id,
          this.slide.id,
          {
            title: this.title,
            text: this.slide.text
          }
      ).then(res => {
        this.$success(this.$t('slide_changed'))
        this.slide = res.data.data
        this.passedslide.title = this.slide.title
        this.changed = false
        this.editing = false
      })
    },
    destroy() {
      SlidesService.delete(
          this.isLightCourse ? 'light' : this.course.id,
          this.material.id,
          this.slide.id
      ).then(() => {
        this.$success(this.$t('slide_deleted'))
        this.$emit('slidedeleted')
      })
    },
  }
}
</script>

