<template>
  <b-modal :id="modalId"
           @show="show"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ lesson ? $t('edit_lesson') : $t('add_lesson') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div :key="`form-${formKey}`">
      <h3>{{ $t('title') }}</h3>
      <InputGroup :label="$t('title')"
                  class="default"
                  type="text"
                  @enter="save"
                  v-model="form.title"/>
      <InputGroup :label="$t('subtitle')"
                  class="default"
                  type="text"
                  @enter="save"
                  v-model="form.subtitle"/>
      <InputGroup :label="$t('order')"
                  class="default"
                  type="number"
                  @enter="save"
                  v-model="form.sort_order"/>
      <InputColorGroup :label="$t('textcolor')"
                       class="default"
                       v-model="form.text_color"/>
      <InputColorGroup :label="$t('backcolor')"
                       class="default"
                       v-model="form.back_color"/>
    </div>
    <template v-slot:modal-footer>
      <b-button v-if="lesson"
                @click="destroy"
                :disabled="loading"
                class="btn-themed btn-white">
        <TrashSvg/>
      </b-button>
      <b-button class="btn-themed"
                :disabled="!valid || loading"
                @click="save">
        {{ $t('save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import InputGroup from "@/views/parts/general/form/InputGroup"
import InputColorGroup from "@/views/parts/general/form/InputColorGroup"
import { CoursesService } from '@/services/api.service'
import TrashSvg from '@/assets/svg/trash2.svg'

export default {
  name: 'AddLightCourseLessonModal',
  components: {
    CloseSvg,
    InputGroup,
    InputColorGroup,
    TrashSvg
  },
  data() {
    return {
      form: {
        title: "",
        subtitle: "",
        moduleId: null,
        model: "lesson"
      },
      image: null,
      loading: false,
      modalId: 'addLightCourseLessonModal',
      formKey: 1
    }
  },
  props: {
    lesson: {},
    courseId: {},
    moduleId: {}
  },
  computed: {
    valid() {
      return this.form.title
    }
  },
  methods: {
    async save() {
      if(this.loading) return
      let formData = new FormData()
      if(this.image && typeof this.image === 'object') {
        formData.append('image', this.image)
      }
      formData = this.appendFormdata(formData, this.form)
      this.loading = true
      try {
        const res = this.lesson?.id
            ? await CoursesService.updateLight(this.lesson.id, formData)
            : await CoursesService.addLight(formData)
        if(res.data.data) {
          this.$emit('reload')
          this.$bvModal.hide(this.modalId)
        }
      } catch (e) {
        this.errResponse(e)
      } finally {
        this.loading = false
      }
    },
    async destroy() {
      const confirm = await this.$confirm(this.$t('are_you_sure_you_want_to_delete'))
      if(!confirm) return
      this.loading = true
      try {
        await CoursesService.deleteLight('lesson', this.lesson.id)
        this.$success(this.$t('course_deleted'))
        this.$bvModal.hide(this.modalId)
        this.$emit('reload')
      } catch (e) {
        this.errResponse(e)
      } finally {
        this.loading = false
      }
    },
    show() {
      this.form.moduleId = this.moduleId
      this.form.title = this.lesson?.title || ""
      this.form.subtitle = this.lesson?.subtitle || ""
      this.form.text_color = this.lesson?.text_color || null
      this.form.back_color = this.lesson?.back_color || null
      this.form.sort_order = this.lesson?.sort_order || 0
      this.formKey++
    }
  }
}
</script>

