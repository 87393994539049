<template>
  <b-modal :id="modalId"
           @show="show"
           modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ material ? $t('edit_exercise') : $t('add_exercise') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div>
      <h3>{{ $t('title') }}</h3>
      <InputGroup :label="$t('title')"
                  class="default"
                  type="text"
                  @enter="save"
                  v-model="form.title"/>
      <InputGroup :label="$t('order')"
                  class="default"
                  type="number"
                  @enter="save"
                  v-model="form.sort_order"/>
    </div>
    <template v-slot:modal-footer>
      <b-button v-if="material"
                @click="destroy"
                :disabled="loading"
                class="btn-themed btn-white">
        <TrashSvg/>
      </b-button>
      <b-button class="btn-themed"
                :disabled="!valid || loading"
                @click="save">
        {{ $t('save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import InputGroup from "@/views/parts/general/form/InputGroup"
import { CoursesService } from '@/services/api.service'
import TrashSvg from '@/assets/svg/trash2.svg'

export default {
  name: 'addLightCourseMaterialModal',
  components: {
    CloseSvg,
    InputGroup,
    TrashSvg
  },
  data() {
    return {
      form: {
        title: "",
        lessonId: null,
        model: "material"
      },
      image: null,
      loading: false,
      modalId: 'addLightCourseMaterialModal',
    }
  },
  props: {
    material: {},
    lessonId: {}
  },
  computed: {
    valid() {
      return this.form.title
    }
  },
  methods: {
    async save() {
      if(this.loading) return
      let formData = new FormData()
      // if(this.image && typeof this.image === 'object') {
      //   formData.append('image', this.image)
      // }
      formData = this.appendFormdata(formData, this.form)
      this.loading = true
      try {
        const res = this.material?.id
            ? await CoursesService.updateLight(this.material.id, formData)
            : await CoursesService.addLight(formData)
        if(res.data.data) {
          this.$emit('reload')
          this.$bvModal.hide(this.modalId)
        }
      } catch (e) {
        this.errResponse(e)
      } finally {
        this.loading = false
      }
    },
    async destroy() {
      const confirm = await this.$confirm(this.$t('are_you_sure_you_want_to_delete'))
      if(!confirm) return
      this.loading = true
      try {
        await CoursesService.deleteLight('material', this.material.id)
        this.$success(this.$t('course_deleted'))
        this.$bvModal.hide(this.modalId)
        this.$emit('reload')
      } catch (e) {
        this.errResponse(e)
      } finally {
        this.loading = false
      }
    },
    show() {
      this.form.lessonId = this.lessonId
      this.form.title = this.material?.title || ""
      this.form.sort_order = this.material?.sort_order || 0
    }
  }
}
</script>

