<template>
  <section id="lightcourses" style="max-width: 700px;">
    <h1 id="page-title">
      <b-row class="align-items-center">
        <b-col>{{ $t('lightcourses') }}</b-col>
        <!--        <b-col class="d-md-none d-block" cols="auto">-->
        <!--          <button class="btn-themed" @click="addCourse">-->
        <!--            <PlusSvg class="svg-white svg-icon-sm"/>-->
        <!--          </button>-->
        <!--        </b-col>-->
      </b-row>
    </h1>
    <div class="mt-4" :class="{ spacecard: !selectMode }">
      <button class="btn-themed btn-white w-100"
              @click="addCourse(null)"
              v-if="!selectMode">
        <PlusSvg class="svg-black svg-icon-sm"/><span class="ml-3">{{ $t('add_course') }}</span>
      </button>
      <div v-for="(c, courseIndex) in courses"
           :key="`course-${c.id}`">
        <AccessoryRow :title="c.title"
                      :subtitle="$t('course')"
                      :addEnabled="!selectMode"
                      :addTitle="$t('add_module')"
                      :editTitle="$t('edit_course')"
                      :editEnabled="!selectMode"
                      @click.native="courseClicked(c, $event)"
                      @edit="addCourse(c)"
                      @add="addModule(courseIndex, c.id)"
                      v-b-toggle="'collapse-course-'+c.id">
          <b-col v-if="c.image" cols="auto">
            <img :src="c.image" class="light-img" :alt="c.title"/>
          </b-col>
        </AccessoryRow>
        <b-collapse :id="'collapse-course-'+c.id"
                    class="pl-sm-5 pl-4 position-relative"
                    @show="loadModules(courseIndex, c.id)">
          <HorizontalLoader v-if="sectionLoader == `${courseIndex}-${c.id}`"
                            class="horizontal-loader"/>
          <div class="alert alert-secondary mt-3"
               v-if="c.modules && !c.modules.length">
            {{ $t('no_data_available') }}
          </div>
          <div v-for="(m, moduleIndex) in c.modules"
               :key="`module-${m.id}`">
            <AccessoryRow :title="m.title"
                          :subtitle="$t('module')"
                          :addTitle="$t('add_lesson')"
                          :editTitle="$t('edit_module')"
                          :addEnabled="!selectMode"
                          :editEnabled="!selectMode"
                          @edit="addModule(courseIndex, c.id, m)"
                          @add="addLesson(courseIndex, moduleIndex, c.id, m.id)"
                          v-b-toggle="'collapse-module-'+m.id"/>
            <b-collapse :id="'collapse-module-'+m.id"
                        class="pl-sm-5 pl-4 position-relative"
                        @show="loadLessons(courseIndex, moduleIndex, c.id, m.id)">
              <HorizontalLoader v-if="sectionLoader == `${courseIndex}-${c.id}-${moduleIndex}-${m.id}`"
                                class="horizontal-loader"/>
              <div class="alert alert-secondary mt-3"
                   v-if="m.lessons && !m.lessons.length">
                {{ $t('no_data_available') }}
              </div>
              <div v-for="(l, lessonIndex) in m.lessons"
                   :key="`lesson-${lessonIndex}`">
                <AccessoryRow :title="l.title + (l.subtitle ? ': ' + l.subtitle : '')"
                              :subtitle="$t('lesson')"
                              :addTitle="$t('add_exercise')"
                              :editTitle="$t('edit_lesson')"
                              :addEnabled="!selectMode"
                              :editEnabled="!selectMode"
                              @edit="addLesson(courseIndex, moduleIndex, c.id, m.id, l)"
                              @add="addMaterial(courseIndex, moduleIndex, lessonIndex, c.id, m.id, l.id)"
                              v-b-toggle="'collapse-lesson-'+l.id"/>
                <b-collapse :id="'collapse-lesson-'+l.id"
                            class="pl-sm-5 pl-4 position-relative"
                            @show="loadMaterials(courseIndex, moduleIndex, lessonIndex, c.id, m.id, l.id)">
                  <HorizontalLoader v-if="sectionLoader == `${courseIndex}-${c.id}-${moduleIndex}-${m.id}-${lessonIndex}-${l.id}`"
                                    class="horizontal-loader"/>
                  <div class="alert alert-secondary mt-3" v-if="l.materials && !l.materials.length">
                    {{ $t('no_data_available') }}
                  </div>
                  <div v-for="(m, materialIndex) in l.materials"
                       :key="`material-${materialIndex}`">
                    <AccessoryRow :title="m.title"
                                  :subtitle="$t('exercise')"
                                  :editTitle="$t('edit_exercise')"
                                  @click.native="goToMaterialConstructor(m.id)"
                                  :editEnabled="!selectMode"
                                  @edit="addMaterial(courseIndex, moduleIndex, lessonIndex, c.id, m.id, l.id, m)"/>
                  </div>
                </b-collapse>
              </div>
            </b-collapse>
          </div>
        </b-collapse>
      </div>
    </div>
    <AddLightCourseModal @reload="reload"
                         :course="selectedCourse"/>
    <AddLightCourseModuleModal @reload="reload"
                               :module="selectedModule"
                               :courseId="selectedCourseId"/>
    <AddLightCourseLessonModal @reload="reload"
                               :lesson="selectedLesson"
                               :moduleId="selectedModuleId"/>
    <AddLightCourseMaterialModal @reload="reload"
                                 :material="selectedMaterial"
                                 :lessonId="selectedLessonId"/>
  </section>
</template>

<script>
import { CoursesService } from "@/services/api.service"
import PlusSvg from '@/assets/svg/plus.svg'
import AddLightCourseModal from "@/views/pages/instructor/courses/AddLightCourseModal.vue"
import AccessoryRow from "@/views/teasers/AccessoryRow.vue"
import AddLightCourseModuleModal from "@/views/pages/instructor/courses/AddLightCourseModuleModal.vue"
import AddLightCourseLessonModal from "@/views/pages/instructor/courses/AddLightCourseLessonModal.vue"
import HorizontalLoader from "../../../parts/general/HorizontalLoader.vue"
import AddLightCourseMaterialModal from "./AddLightCourseMaterialModal.vue"

export default {
  name: "LightCourses",
  components: {
    AddLightCourseMaterialModal,
    HorizontalLoader,
    AddLightCourseLessonModal,
    AddLightCourseModuleModal,
    AccessoryRow,
    AddLightCourseModal,
    PlusSvg
  },
  data() {
    return {
      loaded: false,
      courses: [],
      selectedCourseId: null,
      selectedModuleId: null,
      selectedLessonId: null,
      selectedMaterialId: null,
      courseIndex: null,
      moduleIndex: null,
      lessonIndex: null,
      selectedCourse: null,
      selectedModule: null,
      selectedLesson: null,
      selectedMaterial: null,
      sectionLoader: null
    }
  },
  props: {
    selectMode: {
      type: Boolean,
      default: false
    },
    selectCourse: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async loadCourses() {
      const res = await CoursesService.getLightCourses()
      // console.log(res.data.data)
      this.courses = res.data.data
    },
    async loadModules(index, courseId, force = false) {
      if(!force) {
        if(!this.courses[index] || this.courses[index].modules?.length) return
      }
      this.sectionLoader = `${index}-${courseId}`
      const res = await CoursesService.getLightCourses(false, courseId)
      this.$set(this.courses[index], 'modules', res.data.data)
      setTimeout(() => {
        this.sectionLoader = null
      }, 200)
    },
    async loadLessons(courseIndex, moduleIndex, courseId, moduleId, force = false) {
      if(!force) {
        if (this.courses[courseIndex]?.modules[moduleIndex]?.lessons?.length) return
      }
      this.sectionLoader = `${courseIndex}-${courseId}-${moduleIndex}-${moduleId}`
      const res = await CoursesService.getLightCourses(false, courseId, moduleId)
      this.$set(this.courses[courseIndex].modules[moduleIndex], 'lessons', res.data.data)
      setTimeout(() => {
        this.sectionLoader = null
      }, 200)
    },
    async loadMaterials(courseIndex, moduleIndex, lessonIndex, courseId, moduleId, lessonId, force = false) {
      if(!force) {
        if (this.courses[courseIndex]?.modules[moduleIndex]?.lessons[lessonIndex]?.materials?.length) return
      }
      this.sectionLoader = `${courseIndex}-${courseId}-${moduleIndex}-${moduleId}-${lessonIndex}-${lessonId}`
      const res = await CoursesService.getLightCourses(false, courseId, moduleId, lessonId)
      this.$set(this.courses[courseIndex].modules[moduleIndex].lessons[lessonIndex], 'materials', res.data.data)
      setTimeout(() => {
        this.sectionLoader = null
      }, 200)
    },
    courseClicked(course = null, e) {
      if(this.selectCourse) {
        e.stopPropagation()
        e.preventDefault()
      }
      this.$emit('courseSelected', course)
    },
    addCourse(course = null) {
      this.selectedCourse = course
      this.courseIndex = null
      this.moduleIndex = null
      this.lessonIndex = null
      this.selectedCourseId = null
      this.selectedModuleId = null
      this.selectedLessonId = null
      this.$nextTick(() => {
        this.$bvModal.show('addLightCourseModal')
      })
    },
    addModule(courseIndex, courseId, module = null) {
      this.selectedModule = module
      this.courseIndex = courseIndex
      this.moduleIndex = null
      this.lessonIndex = null
      this.selectedCourseId = courseId
      this.selectedModuleId = null
      this.selectedLessonId = null
      this.$nextTick(() => {
        this.$bvModal.show('addLightCourseModuleModal')
      })
    },
    addLesson(courseIndex, moduleIndex, courseId, moduleId, lesson = null) {
      this.selectedLesson = lesson
      this.courseIndex = courseIndex
      this.moduleIndex = moduleIndex
      this.lessonIndex = null
      this.selectedCourseId = courseId
      this.selectedModuleId = moduleId
      this.selectedLessonId = null
      this.$nextTick(() => {
        this.$bvModal.show('addLightCourseLessonModal')
      })
    },
    addMaterial(courseIndex, moduleIndex, lessonIndex, courseId, moduleId, lessonId, material = null) {
      this.selectedMaterial = material
      this.courseIndex = courseIndex
      this.moduleIndex = moduleIndex
      this.lessonIndex = lessonIndex
      this.selectedCourseId = courseId
      this.selectedModuleId = moduleId
      this.selectedLessonId = lessonId
      this.$nextTick(() => {
        this.$bvModal.show('addLightCourseMaterialModal')
      })
    },
    reload() {
      if(this.selectedCourseId && this.selectedModuleId && this.selectedLessonId) {
        this.loadMaterials(this.courseIndex, this.moduleIndex, this.lessonIndex, this.selectedCourseId, this.selectedModuleId, this.selectedLessonId, true)
        return
      }
      if(this.selectedCourseId && this.selectedModuleId) {
        this.loadLessons(this.courseIndex, this.moduleIndex, this.selectedCourseId, this.selectedModuleId, true)
        return
      }
      if(this.selectedCourseId) {
        this.loadModules(this.courseIndex, this.selectedCourseId, true)
        return
      }
      this.loadCourses()
    },
    goToMaterialConstructor(materialId) {
      if(this.selectMode) {
        this.$emit('materialSelected', materialId)
        return
      }
      this.$router.push({
        name: this.$store.state.user.primary_role_id == 4 ? 'SchoolLightCourseConstructor' : 'LightCourseConstructor',
        params: {
          materialId
        }
      })
    },
  },
  async mounted() {
    await this.loadCourses()
    this.loaded = true
  },
  metaInfo() {
    return {
      title: this.$t('lightcourses'),
    }
  },
}
</script>

<style scoped>
.horizontal-loader {
  position: absolute;
  width: calc(100% - 30px);
  left: 15px;
}
.light-img {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}
</style>
