<template>
  <div class="material-page">
    <h1 id="page-title">
      <router-link v-if="canEdit" :to="roleLinkAppendix()+'/lightcourses'">
        <BackSvg class="mr-3"/>
      </router-link>
      <span v-if="canEdit">{{ $t('lesson_constructor') }}</span>
      <span v-if="material && !canEdit">{{ material.title }}</span>
    </h1>
    <div v-if="material && (canEdit || methodistLimitedAccess)">
      <b-row>
        <b-col lg="8">
          <div id="scroll-fixer">
            <div>
              <Slide v-if="slideSelected && !showmodal"
                     :material="material"
                     :canEdit="canEdit"
                     :passedslide="slideSelected"
                     :index="slideSelectedIndex"
                     @slidedeleted="slideDeleted"
              />
              <div v-if="!slideSelected" class="spacecard min-h-card d-flex justify-content-center align-items-center">
                {{ $t('slide_not_selected') }}
              </div>
              <div v-if="showmodal" class="spacecard">{{ $t('preview') }}</div>
            </div>
          </div>
        </b-col>

        <b-col lg="4">
          <affix class="spacecard md mb-3 slides-holder mt-lg-0 mt-3"
                 relative-element-selector="#scroll-fixer"
                 :enabled="!mobile"
                 :offset="{ top: 15, bottom: 15 }">
            <div class="btns">
              <b-row @click="showMaterialsMenu" class="pointer align-items-center">
                <b-col cols="auto">
                  <LetterIcon :obj="material"
                              :title="material.title"
                              :key="'add-'+key"
                              class="student-icon sm"/>
                </b-col>
                <b-col>
                  <div>{{ material.title }}</div>
                </b-col>
                <b-col cols="auto">
                  <DownSvg class="down-svg"/>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <a class="btn-themed btn-white w-100" @click="preview">{{ $t('preview') }}</a>
                </b-col>
                <b-col cols="auto" v-if="canEdit">
                  <a class="pointer btn-themed btn-white" @click="editMaterial"><PenSvg class="svg-icon-sm"/></a>
                </b-col>
              </b-row>

              <div class="mt-3">
                <a class="btn-whole-add btn-whole-add-dashed"
                   v-if="canEdit"
                   @click="addSlide">
                  <PlusSvg class="mr-2 svg-icon-sm"/>{{ $t('add_slide') }}
                </a>
              </div>
            </div>
            <div class="mt-3" v-if="slides.length">
              <draggable v-model="slides"
                         @end="draggedSlide"
                         :options="{ disabled: !canEdit }">
                <SliderTeaser v-for="(slide,sid) in slides"
                              :key="'slide-'+sid"
                              :canEdit="canEdit"
                              :class="{ active: slideSelected && (slide.id === slideSelected.id) }"
                              :slide="slide"
                              @selected="selectSlide(sid, slide)"
                              @delete="deleteSlide(sid, slide.id)"/>
              </draggable>
            </div>
          </affix>
        </b-col>
      </b-row>
      <AddLightCourseMaterialModal @reload="materialUpdated"
                                   :material="material"
                                   :lessonId="material.light_course_lesson_id"/>
      <PresentationPreviewModal v-if="showmodal"
                                :material="material"
                                :homework="false"/>
      <SelectMaterial :materialPassed="material"
                      @selected="materialSelected"/>
    </div>
    <div v-if="material && !canEdit"></div>
  </div>
</template>

<script>

import {
  MaterialsService,
  SlidesService,
} from '@/services/api.service'
import PenSvg from '@/assets/svg/pen.svg'
import PlusSvg from '@/assets/svg/plus.svg'
import BackSvg from '@/assets/svg/back.svg'
import DownSvg from '@/assets/svg/menu2.svg'
import SliderTeaser from '@/views/teasers/SliderTeaser'
import PresentationPreviewModal from '@/views/parts/class/preview/PresentationPreviewModal'
import Slide from '@/views/pages/instructor/courses/Slide'
import EventBus from '@/services/event-bus'
import LetterIcon from '@/views/parts/general/LetterIcon'
import SelectMaterial from '@/views/parts/class/SelectLightCourseMaterial'
import draggable from 'vuedraggable'
import { Affix } from 'vue-affix'
import AddLightCourseMaterialModal from "./AddLightCourseMaterialModal.vue"

export default {
  components: {
    AddLightCourseMaterialModal,
    PenSvg,
    PlusSvg,
    DownSvg,
    BackSvg,
    SliderTeaser,
    Slide,
    draggable,
    PresentationPreviewModal,
    LetterIcon,
    Affix,
    SelectMaterial
  },
  data() {
    return {
      material: null,
      changed: false,
      slides: [],
      showmodal: false,
      slideSelected: null,
      slideSelectedIndex: null,
      cid: 'light',
      slidemode: 'lesson',
      mobile: false,
      words: [],
      cards: [],
      key: 1
    }
  },
  props: {
  },
  mounted() {
    this.load()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === "presentationpreviewmodal") {
        this.showmodal = false
      }
    })
    this.resized()
    window.addEventListener('resize', this.resized)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resized)
  },
  computed: {
    canEdit() {
      let user = this.$store.state.user
      if(user.primary_role_id == 3) {
        return true
      }
      if(user.primary_role_id == 6) {
        return true
      }
      return true
    },
    materialId() {
      return this.$route.params.materialId
    },
    methodistLimitedAccess() {
      let user = this.$store.state.user
      if(user.primary_role_id != 6 || !this.course) {
        return false
      }
      return this.course.instructor_id != user.id
    }
  },
  watch: {
    materialId() {
      this.load()
    },
  },
  methods:  {
    async load() {
      const res = await MaterialsService.getLightCourseMaterial(this.materialId)
      this.material = res.data.data
      if(!this.material) {
        this.$router.push({ name: 'LightCourses' })
        return
      }
      this.slides = this.material.slides
      if(this.slides.length > 0) {
        this.slideSelected = this.slides[0]
      } else {
        this.slideSelected = null
      }
      this.breadcrumbs()
    },
    editMaterial() {
      this.$bvModal.show('addLightCourseMaterialModal')
    },
    materialUpdated() {
      this.load()
    },
    addSlide() {
      SlidesService.add(this.cid, this.materialId, {
        title: "Слайд #"+(this.slides.length + 1),
        type: this.slidemode
      }).then(res => {
        this.slides.push(res.data.data)
        this.slideSelected = this.slides[(this.slides.length - 1)]
      })
    },
    deleteSlide(index, id) {
      SlidesService.delete(this.cid, this.materialId, id).then(() => {
        this.slides.splice(index, 1)
        this.slideSelected = null
      })
    },
    selectSlide(index, slide, type = null) {
      this.slideSelectedIndex = index
      if(type) slide.type = type
      this.slideSelected = slide
    },
    slideDeleted() {
      this.slides = this.slides.filter((obj) => {
        return obj.id !== this.slideSelected.id
      });
      this.slideSelected = null
    },
    draggedSlide() {
      MaterialsService.resort(this.cid, this.materialId, {
        slides: this.slides,
        type: this.slidemode
      }).then(res => {
        console.log(res)
      })
    },
    preview() {
      this.showmodal = true
      this.$nextTick(() => {
        this.$bvModal.show('presentationpreviewmodal')
      })
    },
    breadcrumbs() {
      EventBus.$emit('breadcrumbs',[
        { to: '/courses', title: this.$t('courses') },
        { to: '', title: this.material.title },
      ])
    },
    resized() {
      this.mobile = window.innerWidth <= 992
    },
    showMaterialsMenu() {
      this.$bvModal.show('selectLightCourseMaterial')
    },
    materialSelected(materialId) {
      this.$router.push({
        name: 'LightCourseConstructor',
        params: {
          materialId
        }
      })
    }
  },
  metaInfo() {
    return {
      title: this.$t('lesson_constructor'),
    }
  },
}
</script>

<style scoped lang="scss">
.material-page {
  .slides-holder {
    padding-left: 0;
    .btns {
      padding-left: 24px;
    }
    .btn-whole-add-dashed {
      height: 46px;
      line-height: 46px;
    }
  }
  .vue-affix {
    width: 380px;
  }
}
@media(max-width: 992px) {
  .material-page {
    .vue-affix {
      width: 100%;
    }
  }
}

@media(max-width: 768px) {
  .material-page {
    .vue-affix {
      width: 100%;
    }
    .slides-holder {
      .btns {
        padding-left: 16px;
      }
    }
  }
}
</style>


