<template>
    <div class="form-group">
        <div v-if="value && deletefile && !fileremoved && !selected" class="position-relative d-inline-block">
            <img alt="photo" class="img-preview" :src="value"/>
            <a class="removeFile" @click="deleteFile">
                <CloseSvg/>
            </a>
        </div>
        <div v-else>
            <label class="form-label mb-0" :for="fid">
                <!--            <span>{{ $t(label) }}</span>-->
                <span class="ml-2" v-if="formatnote">({{ $t(formatnote) }})</span>
                <span class="form-control form-control-white file-form-control">
                <span class="form-placeholder">{{ name }}</span>
                <span class="file-btn selected" v-if="selected">{{ $t('selected') }}</span>
                <span class="file-btn" v-else>{{ $t('select') }}</span>
            </span>
            </label>
            <input type="file" v-if="accept" :accept="accept" class="form-control d-none" :id="fid"  @change="processFile($event)"/>
            <input type="file" v-else class="form-control d-none" :id="fid"  @change="processFile($event)"/>
        </div>
    </div>
</template>

<script>
    import CloseSvg from "@/assets/svg/close.svg"

    export default {
        components: {
            CloseSvg
        },
        data() {
            return {
                name: this.label,
                selected: false,
                sizelimit: 1, // in MB
                fileremoved: false,
            }
        },
        props: {
            label: {},
            deletefile: { type: Boolean, default: false },
            formatnote: {},
            value: {},
            type: {},
            fid: {},
            accept: {}
        },
        mounted() {
        },
        methods: {
            processFile(event) {
                let file = event.target.files[0]
                if(file.size > (this.sizelimit * 1024 * 1024)) {
                    this.$error(this.$t('file_size_is_too_big'))
                    return
                }
                this.name = file.name
                this.selected = true
                this.$emit('input', file)
            },
            deleteFile() {
                this.fileremoved = true
                this.$emit('deleteFile')
            }
        }
    }
</script>

<style scoped lang="scss">
    .file-btn {
        &.selected {
            background: #000;
            color: #fff;
        }
    }
    .img-preview {
        max-height: 120px;
        max-width: 120px;
        border-radius: 16px;
    }
    .removeFile {
        position: absolute;
        top: -15px;
        right: -15px;
        box-shadow: 0px 0px 10px rgba(146, 169, 176, 0.18);
        border-radius: 100%;
        cursor: pointer;
        svg {

            width: 30px;
            height: 30px;
        }
    }
</style>