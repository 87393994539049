<template>
  <div class="spacecard bg-light border-0 p-4">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col">{{ $t('rate') }}</div>
          <div class="col-auto">{{ localConfig.rate }}</div>
        </div>
        <input type="range" id="points" name="points" min="0.3" step="0.1" max="4" class="w-100" v-model="localConfig.rate">
      </div>
      <div class="col-sm-6 mt-sm-0 mt-2">
        <div class="row">
          <div class="col">{{ $t('pitch') }}</div>
          <div class="col-auto">{{ localConfig.pitch }}</div>
        </div>
        <input type="range" id="points" name="points" min="-10" step="0.5" max="10" class="w-100" v-model="localConfig.pitch">
      </div>
      <div class="col-sm-4 mt-2">
        <SelectGroup class="default"
                     v-model="localConfig.gender"
                     :options="genders"/>
      </div>
      <div class="col-sm-4 mt-2">
        <SelectGroup class="default"
                     v-model="localConfig.locale"
                     :options="locales"/>
      </div>
      <div class="col-sm-4 mt-2" v-if="init">
        <SelectGroup class="default"
                     :translate="false"
                     v-model="localConfig.name"
                     :options="namesOptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import SelectGroup from "../../../parts/general/form/SelectGroup"

export default {
  name: "AudioConfigPart",
  components: {
    SelectGroup
  },
  props: {
    value: {}
  },
  data() {
    return {
      localConfig: {
        rate: 1,
        pitch: 1,
        gender: 'male',
        name: 'en-US-Neural2-A',
        locale: 'en-US'
      },
      init: false,
      genders: [
        { id: 'male', title: 'male' },
        { id: 'female', title: 'female' },
      ],
      locales: [
        { id: 'en-US', title: 'american' },
        { id: 'en-GB', title: 'british' },
      ],
      names: [
        { gender: 'male', locale: 'en-US', id: 'Neural2-A', title: 'Neural2-A' },
        { gender: 'male', locale: 'en-US', id: 'Neural2-D', title: 'Neural2-D' },
        { gender: 'male', locale: 'en-US', id: 'Neural2-I', title: 'Neural2-I' },
        { gender: 'male', locale: 'en-US', id: 'Neural2-J', title: 'Neural2-J' },
        { gender: 'female', locale: 'en-US', id: 'Neural2-C', title: 'Neural2-C' },
        { gender: 'female', locale: 'en-US', id: 'Neural2-E', title: 'Neural2-E' },
        { gender: 'female', locale: 'en-US', id: 'Neural2-F', title: 'Neural2-F' },
        { gender: 'female', locale: 'en-US', id: 'Neural2-G', title: 'Neural2-G' },
        { gender: 'female', locale: 'en-US', id: 'Neural2-H', title: 'Neural2-H' },
        { gender: 'male', locale: 'en-GB', id: 'Neural2-B', title: 'Neural2-B' },
        { gender: 'male', locale: 'en-GB', id: 'Neural2-D', title: 'Neural2-D' },
        { gender: 'female', locale: 'en-GB', id: 'Neural2-A', title: 'Neural2-A' },
        { gender: 'female', locale: 'en-GB', id: 'Neural2-C', title: 'Neural2-C' },
        { gender: 'female', locale: 'en-GB', id: 'Neural2-F', title: 'Neural2-F' },


      ]
    }
  },
  computed: {
      namesOptions() {
        if(!this.localConfig.gender || !this.localConfig.locale) return []
        return this.names.filter(name => name.gender === this.localConfig.gender).filter(name => name.locale === this.localConfig.locale)
      }
  },
  mounted() {
    if(this.value) {
      this.localConfig.rate = this.value.rate || 1
      this.localConfig.pitch = this.value.pitch || 0
      this.localConfig.gender = this.value.gender || 'male'
      this.localConfig.name = this.value.name || 'Neural2-A'
      this.localConfig.locale = this.value.locale || 'en-US'
    }
    this.init = true
  },
  watch: {
    localConfig: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  }
}
</script>
