<template>
  <div class="slide-teaser" @click="$emit('selected')">
    <b-row class="align-items-center position-relative">
      <b-col lg>
        <div class="slide-teaser__title">
          <component :is="icon" class="mr-2"/>
          {{ slide.title }}
        </div>
      </b-col>
      <b-col lg="auto" v-if="canEdit">
        <CloseSvg class="pointer slide-teaser__close"
                  @click.stop="$emit('delete')"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import CloseSvg from '@/assets/svg/close.svg'
import SlideSvg from '@/assets/svg/slide.svg'
import PdfSvg from '@/assets/svg/pdf.svg'
import AudioSvg from '@/assets/svg/audio2.svg'

export default {
  name: 'SliderTeaser',
  components: {
    CloseSvg,
    SlideSvg,
    AudioSvg,
    PdfSvg
  },
  data() {
    return {

    }
  },
  props: {
    slide: {},
    icon: {
      default: 'SlideSvg'
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  methods: {

  },
  watch: {

  },
  mounted() {

  },

}
</script>

<style scoped lang="scss">
.slide-teaser {
  cursor: pointer;
  padding: 13px 0 13px 24px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  &.active, &:hover {
    background-color: $almost-white;
  }
  &:last-of-type {
    border-bottom: none;
  }
  &__close {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 14px;
    top: 50%;
    margin-top: -12px;
    &:hover {
      opacity: 0.5;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    word-break: break-all;
  }
  &:hover {
    .slide-teaser__close {
      display: block;
    }
  }
}

@media(max-width: 768px) {
  .slide-teaser {
    padding: 11px 0 11px 16px;
    &__title {
      font-size: 14px;
    }
    &__close {
      margin-top: -22px;
    }
  }
}
</style>






