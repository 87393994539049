<template>
  <b-modal id="slide-copy-select" 
           modal-class="modal-right-pane"
           @show="onShow"
           @hide="onHide"
           centered>
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('choose_slide') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div class="mt-4">
      <SelectGroup class="default"
                   v-model="courseId"
                   :label="$t('course')"
                   :floatinglabel="true"
                   :translate="false"
                   :hasNullOption="false"
                   :options="courses"/>
      <SelectGroup class="default"
                   v-model="materialId"
                   :label="$t('material')"
                   :floatinglabel="true"
                   :translate="false"
                   :hasNullOption="false"
                   :options="materials"/>
      <SelectGroup class="default" 
                   v-model="slideGroup"
                   :label="$t('group')"
                   :floatinglabel="true"
                   :translate="true" 
                   :hasNullOption="false"
                   :options="slidesOptions"/>
      <SelectGroup class="default"
                   v-model="newSlide"
                   :label="$t('slide')"
                   :floatinglabel="true"
                   :translate="false"
                   :options="slides[slideGroup]"/>
    </div>
    <template v-slot:modal-footer>
      <b-button class="btn-themed"
                :disabled="!newSlide"
                @click="save">
        {{ $t('save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import CloseSvg from "@/assets/svg/close.svg"
import {mapState} from "vuex"
import {ConstructorService, LessonsService, CoursesService} from "../../../../services/api.service"
import SelectGroup from "@/views/parts/general/form/SelectGroup"

export default {
  name: "CopyComponentModal",
  components: {
    CloseSvg,
    SelectGroup
  },
  computed: {
    ...mapState({
      componentToCopy: state => state.material.componentToCopy,
    }),
    // materialId() {
    //   return this.$route.params.mid
    // }
  },
  data() {
    return {
      courseId: null,
      materialId: null,
      courses: [],
      materials: [],
      slides: [],
      newSlide: null,
      slideGroup: 'lesson',
      slidesOptions: [
        { id: 'lesson', title: 'lesson'},
        { id: 'homework', title: 'homework'},
      ]
    }
  },
  methods: {
    onShow() {
      this.materialId = this.$route.params.mid 
      this.courseId = this.$route.params.cid 
      this.getMaterials()
      this.getSlidesForMaterial()
    },
    onHide() {
      this.materialId = null
      this.courseId = null
      this.newSlide = null
      this.slides = []
    },
    getSlidesForMaterial() {
      this.newSlide = null
      this.slides = []
      LessonsService.getSlidesForMaterial(this.materialId).then(res => {
        this.slides = res.data.data.slides
      })
    },
    getMaterials() {
      CoursesService.get().then(res => {
        let c = res.data.data.courses
        this.courses = c.custom.concat(c.standard, c.school)
        this.setMaterials()
      })
    },
    setMaterials() {
      let course = this.courses.find(c => c.id == this.courseId)
      this.materials = course ? course.materials : []
      this.materialId = this.materials.length ? this.materials[0].id : null  
    },
    async save() {
      let res = await ConstructorService.copyBlockToSlide(this.componentToCopy.slide_id, this.componentToCopy.id, this.newSlide)
      this.$emit('componentsUpdate', res.data.data)
      this.$bvModal.hide('slide-copy-select')
    }
  },
  watch: {
    slideGroup() {
      this.newSlide = null
    },
    materialId(val) {
      if(val) {
        this.getSlidesForMaterial()
      }
    },
    courseId(val) {
      if(val) {
        this.setMaterials()
      }
    }
  }
  
}
</script>

<style scoped>

</style>
